.nav {
    height: 5rem;
}

.nav-heading {
    margin-left: 3rem;
}

.nav-links {
    margin-right: 5rem;
    font-size: 1.4rem;
    font-weight: bold;
}